<template>
  <svg
    class="IntrinsicSVG"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="`0 0 ${width || size || 256} ${height || size || 256}`"
    :width="width || size || 256"
    :height="height || size || 256"
  ></svg>
</template>

<script>
export default {
  props: ["size", "width", "height"],
};
</script>

<style lang="scss">
.IntrinsicSVG {
  max-width: 100%;
  height: auto;
}
</style>
